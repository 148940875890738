import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Accordion from '../components/Accordion/Accordion'
import { useParams } from 'react-router-dom';
import burstgif from "./../assets/animations/burst.gif"
import arrow from './../assets/images/icons/Arrow-outline-black-sm.svg'
import gridBtn from './../assets/images/icons/GridBTN.svg'

const ProductDetail = ({isSearchOpen, setIsProductsOpen}) => {
    const [isBurstHidden, setIsBurstHidden] = useState(false)
    const { slug  } = useParams()

    const { t } = useTranslation('translation',{keyPrefix:'product.details'});
    
    const flavors = useTranslation('translation').t("products", {returnObjects: true })

    const {
        name,
        size,
        description,
        ingredients,
        nutrition,
        pack_size

    } = flavors.find(flavor => flavor.slug === slug )

    useEffect(()=> {
        window.scrollTo(0,0)
        setTimeout(() => {
            setIsBurstHidden(true)
        }, 2500);
        return () => {
            setIsBurstHidden(false)
        }
    },[slug])

    return (
        <main className={isSearchOpen ? "main-search-open":"main-search-close"}>
            <section className="relative flex flex-col md:flex-row container mx-auto mt-1">
                <button className='absolute  size-10 right-3 top-10 pointer z-30' onClick={()=> setIsProductsOpen(true)}>
                    <img className={`ml-auto group-hover:-rotate-90 transition-all ease-in-out`} src={gridBtn} alt="" />
                </button>
                <div className="relative lg:sticky top-[0] lg:top-[120px] self-start w-full md:w-5/12 my-9">
                    <picture className=''>
                        <source srcSet={require(`./../assets/images/products/${slug}@3x.png`)} media="(min-width: 768px)" />
                        <img className="block mx-auto z-0" src={require(`./../assets/images/products/${slug}@2x.png`)} alt="Pepsi" />
                    </picture>
                    <img className={`absolute inset-0 ${isBurstHidden ? "opacity-0" : "opacity-1"} group-hover:opacity-100 transition-opacity duration-1250 h-full xl:rounded-full w-auto object-cover -z-10`} src={burstgif} alt="" />
                </div>
                <div className="flex flex-col w-full md:w-7/12 px-3 z-0">
                    <h1 className="text-5xl font-pepsiowners-extended text-electric-blue my-9">
                        {name}
                        <br />
                        <span className="text-4xl text-white">{size}</span>
                    </h1>
                    <Accordion title={t('description')} isDefaultOpen={true}>
                        <p>{description}</p>
                    </Accordion>
                    <Accordion title={t("nutrition")}>
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <td>
                                        {t("serving_size")} {size}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t("amount_per_serving")}</td>
                                    <td>{t("percent_daily_value")}</td>
                                </tr>
                            </thead>
                            <tbody> 
                                <tr className="border-t-2 border-white bg-vivid-blue">
                                    <td className="">{t("information.calories")}</td>
                                    <td className="" colspan="2">
                                    {nutrition.calories.serving_size}
                                    </td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td>{t("information.fat")}</td>
                                    <td>{nutrition.fat.serving_size}</td>
                                    <td>{nutrition.fat.percent_daily_value}{nutrition.fat.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td>{t("information.carbohydrates")}</td>
                                    <td>{nutrition.carbohydrates.serving_size}</td>
                                    <td>{nutrition.carbohydrates.percent_daily_value}{nutrition.carbohydrates.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td className="pl-5 font-normal">{t("information.sugar")}</td>
                                    <td>{nutrition.sugar.serving_size}</td>
                                    <td>{nutrition.sugar.percent_daily_value}{nutrition.sugar.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td>{t("information.protein")}</td>
                                    <td>{nutrition.protein.serving_size}</td>
                                    <td>{nutrition.protein.percent_daily_value}{nutrition.protein.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td>{t("information.sodium")}</td>
                                    <td>{nutrition.sodium.serving_size}</td>
                                    <td>{nutrition.sodium.percent_daily_value}{nutrition.sodium.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td>{t("information.potassium")}</td>
                                    <td>{nutrition.potassium.serving_size}</td>
                                    <td>{nutrition.potassium.percent_daily_value}{nutrition.potassium.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                                <tr className="odd:bg-vivid-blue">
                                    <td>{t("information.iron")}</td>
                                    <td>{nutrition.iron.serving_size}</td>
                                    <td>{nutrition.iron.percent_daily_value}{nutrition.iron.percent_daily_value !== null ? "%" : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                        <p className='text-sm font-sans mt-7' dangerouslySetInnerHTML={{__html: nutrition.disclaimer}}/>
                        </div>
                    </Accordion>
                    <Accordion title={t("ingredients")}>
                        <p dangerouslySetInnerHTML={{__html: ingredients}}/>
                    </Accordion>
                    <Accordion title={t("package_sizes")}>
                        <p dangerouslySetInnerHTML={{__html: pack_size.join(", ")
                            .replaceAll(" ", "&nbsp;")
                            .replaceAll(",&nbsp;", ", ")}
                            }></p>
                        {/* <p>{pack_size.join(", ").replaceAll(" ", "&nbsp;")}</p> */}
                    </Accordion>
                </div>
            </section>
            {/* <section className="bg-gradient-to-b from-black to-globe-blue py-10">
                <h2 className="header-3">View additional Product Sizes</h2>
                <div className="flex flex-wrap gap-5 justify-center p-32">
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">335mL</a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">473mL</a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">1 l</a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">2 l</a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">222mL</a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">6 x 710ml</a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="block size-56 mb-5 object-contain" src="/_next/static/media/pepsi@3x.d5cd0be9.png" alt="" /><a className="pill-btn border-electric-blue hover:bg-electric-blue" href="/products/pepsi">12 x 355mL</a>
                    </div>
                </div>
            </section> */}
        </main>
    )
}

export default ProductDetail