import React, {forwardRef} from 'react'
import { useTranslation } from 'react-i18next';

const Social = forwardRef(function Social(props, ref) {

    const { t } = useTranslation('translation', { keyPrefix: 'homepage' });

    const socials = t('social', { returnObjects: true }).map((item, i) => (
        <a key={"social-two-" + item.name} className="group overflow-hidden relative size-full md:size-80 md:size-auto flex-shrink-0 flex-grow-0" target="_blank" rel="noreferrer" aria-label={`${item.name} ,opens in a new tab`} href={item.url}>
            <div className="absolute size-full flex z-10 group-hover:bg-black/30 transition-colors ease-in-out">
                <img className="block absolute size-1/5 left-3 bottom-3 group-hover:inline" src={require(`./../../assets/images/icons/Social-${item.name}.svg`)} alt="" />
            </div>
            <img className="object-fill size-full transform group-hover:rotate-6 group-hover:scale-110 trasiton-all duration-500 ease-in-out" src={require(`./../../assets/images/socials/social-bg-${i + 1}.png`)} alt="" />
        </a>
    ))

    return (
        <section ref={ref} className="container mx-auto py-16">
            <div className="container mx-auto">
                <h2 className="header-2 mb-12">{t('get_social')}</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 border-4 border-vivid-blue">
                    {socials}
                </div>
            </div>
        </section>
    )
})

export default Social