import React, { useEffect, useRef} from 'react'
import Social from '../components/Socials/Social';
import Carousel from '../components/Carousel/Carousel';
import Hero from '../components/Hero/Hero';
import { useLocation } from 'react-router-dom';

const Home = ({isSearchOpen}) => {

  const {hash} = useLocation()
  const divRef = useRef()

  useEffect(()=> {
    const {current} = divRef
    if(hash === "#get-social") {
      setTimeout(()=>{
        current.scrollIntoView({ behavior: "smooth", block: "start", })
      },500)
    } else {
      window.scrollTo(0,0)
    }
  })


  return (
    <main className={isSearchOpen ? "main-search-open":"main-search-close"}>
      <Hero />
      <Carousel />
      <Social ref={divRef} />
    </main>
  )
}

export default Home