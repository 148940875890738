import React from 'react'
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import arrow from './../../assets/images/icons/Arrow-outline-white-sm.svg'
import { Link } from "react-router-dom";

export const NextArrow = ({ onClick }) => {
  return (
  <div aria-label="next" className="absolute z-20 size-12 top-0 bottom-0 my-auto right-5 cursor-pointer" onClick={onClick}>
    <img className="size-full object-contain" src={arrow} alt="" />
  </div>
  )
}
export const PrevArrow = ({ onClick }) => {
  return (
  <div aria-label="previous" className="absolute z-20 size-12 top-0 bottom-0 my-auto left-5 cursor-pointer rotate-180"  onClick={onClick}>
    <img className="size-full object-contain" src={arrow} alt="" />
  </div>
  )
}


const Carousel = () => {
  const { t } = useTranslation('translation');
  const products = t("products", {returnObjects: true })
  
  const slides = products.map(item => (
    <Link key={"slide-" + item.slug} to={`/product/${item.slug}`} className="relative px-5" >
      <div  className="flex flex-col items-center justify-stretch">
        <h2 className="absolute font-pepsiowners px-6 md:px-0 text-2xl text-center mb-5">{item.name}</h2>
        <img className="my-5 mt-20 w-56" src={require(`./../../assets/images/products/${item.slug}@3x.png`)} alt="" />
      </div>
    </Link>

  ))



  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    adaptiveHeight: true,
    responsive: [
      { breakpoint: 1120, 
        settings: { 
          slidesToShow: 4
        } },
      { breakpoint: 900, 
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 2
         } },
      { breakpoint: 706, settings: { 
        slidesToShow: 1,
        slidesToScroll: 1
      } },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section className="relative w-full py-20 max-w-screen bg-gradient-to-b from-black to-royal">
      <h2 className="relative header-1 mb-20 z-10">
          {t('homepage.carousel.title')}<br />
          <span className="header-1-span">{t('homepage.carousel.title_span')}</span>
      </h2>
      <Slider {...settings}>
        {slides}
      </Slider>
      <div className="absolute h-full w-2/12 z-0 top-0 bottom-0 bg-gradient-to-l from-transparent to-royal"></div>
      <div className="absolute h-full w-2/12 z-0 top-0 bottom-0 right-0 bg-gradient-to-r from-transparent to-royal"></div>
    </section>
  )
}

export default Carousel