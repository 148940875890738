import React from "react";
import ReactPlayer from "react-player";
import herovid from "./../../assets/videos/PEPSI_2024_PR_Sizzle_1920px_pepsi-ca.mp4"

const Hero = () => {
  return (
    <section className="w-full h-auto">
      <ReactPlayer 
      className="w-full h-auto"  
      url={herovid} 
      playing={true}
      loop={true}
      muted={true}
      width="100%"
      height="auto"
      />
    </section>
  );
};

export default Hero;
