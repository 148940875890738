import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import closeIcon from './../../assets/images/icons/BTN_Close.svg'
import searchIcon from './../../assets/images/icons/Search.svg'
import { Link } from "react-router-dom";

const Search = ({searchVal, onChange, isSearchOpen, setIsSearchOpen}) => {
  const flavors = useTranslation('translation').t("products", {returnObjects: true })
  const results = (searchVal?.length > 1) ? flavors.filter(product => product.name.toLowerCase().includes(searchVal?.toLowerCase())) : []
  const drawer =useRef()

  useEffect(() =>{
    drawer.current.addEventListener("blur", setIsSearchOpen(false))

    return (
      drawer.current.removeEventListener("blur", setIsSearchOpen(false))
    )
  },[])


  const tiles = results.map(tile => (
    <Link key={"tile-" + tile.slug} className="flex flex-col items-center justify-start w-56" to={`/product/${tile.slug}`} onClick={(e) => setIsSearchOpen(false)} aria-label={tile.name}>
      <div className="w-36">
        <div className="bg-royal hover:bg-digital-alt-blue transition-colors duration-100 ease-in-out size-36 rounded-md p-3 mb-3">
          <img className="size-[95%] object-contain m-auto" alt="" src={require(`./../../assets/images/products/${tile.slug}.png`)} />
        </div>

        <h3 className="results">{tile.name}</h3>
        <h4 className="results-more">{tile.size}</h4>
      </div>
    </Link>
  ))


  return (
    <section className="fixed top-[100px] lg:top-[90px] w-full z-30" ref={drawer}>
        <div className={`absolute bg-black w-full ${isSearchOpen ? "search-open":"search-close"} transition-all duration-500 ease-in overflow-hidden z-20 border-b-2 border-b-white`}>
            <button className="group absolute size-5 right-0 m-8" tabIndex="0" aria-label="menu">
              <img className="object-contain size-full transition-[transform] duration-300 ease-in group-hover:rotate-90" onClick={(e) => setIsSearchOpen(false)} src={closeIcon} alt="" />
            </button>
            <div className="relative lg:hidden">
                <input className="block w-fit my-11 mx-auto rounded-full border-2 bg-transparent border-white px-7 py-2 pl-14" onChange={onChange} value={searchVal} />
                <img className="absolute inset-0 m-auto -left-48" src={searchIcon} alt="" />
            </div>
            {searchVal !== undefined ?
              <div className="flex flex-wrap max-h-[72vh] overflow-y-scroll justify-center py-8 pl-6 md:pl-0 gap-6 lg:gap-12">
              {results.length ?
                tiles :
                <p className="header-3">Sorry, no products found</p>
              }
            </div> : ""}
        </div>
        <div className={`absolute ${isSearchOpen ? "search-open":"search-close"} size-screen h-[100vh] inset-0 top-0 opacity-0`} onClick={(e) => setIsSearchOpen(false)}></div>
    </section>
  )
}

export default Search