import React, {useEffect} from 'react'
import logo from './../../assets/global/PepsiLogo.svg'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Footer = ({isSearchOpen, children}) => {
  const getLanguage = useTranslation('translation')
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });
  const socials = useTranslation('translation',{ keyPrefix: 'homepage' })


  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.async = 'async'
    scriptTag.crossOrigin = ''
    scriptTag.src = `//consent.trustarc.com/notice?domain=pepsico-ca-beverages.com&c=teconsent&js=nj&noticeType=bb&cookieLink=https://www.pepsico.ca/legal/privacy-policy&text=true&language=${getLanguage.t("current_lang")}`
    document.body.appendChild(scriptTag)
  }, [])
  
  const socialIcons = socials.t('social', { returnObjects: true }).map(item => (
    <Link key={"social-icon" + item.name} className="block" to={item.url} target="_blank" rel="noreferrer" aria-label={`${item.name} ,opens in a new tab`}>
      <img className="size-12 object-fill" src={require(`./../../assets/images/icons/Social-${item.name}.svg`)}  alt="" />
    </Link>
  ))

  const date = new Date()

  const legals = t("legals", { returnObjects: true }).map(link => (
    <li key={"legals-" + link.name} className='text-center text-[14px] legal md:w-1/3 mb-2 px-3'>
      <Link to={link.url} >{link.name}</Link>
    </li>
  ))


  return (
    <footer className={`${isSearchOpen ? "main-search-open":"main-search-close"} pt-9 lg:pt-0`}>
      <div className='lg:container h-full mx-auto flex flex-col lg:flex-row items-center'>
        <div className='w-full lg:max-w-[152px] flex flex-col items-center justify-center lg:mr-auto'>
          <Link to='/' aria-label={t("home")}>
            <img className='size-[76px] md:size-[106px] lg:size-[104px] object-contain lg:mb-2' src={logo} alt="" />
          </Link>
          <p className='hidden lg:inline text-xs'>{t('copyright')} {date.getFullYear()}</p>
        </div>
        <ul className='flex flex-col md:flex-row md:flex-wrap justify-center my-[35px] md:my-[49px] lg:my-0'>
          {legals}
          <li className='text-center text-[14px] legal md:w-1/3 mb-2 px-3'>
            {/* <div id="teconsent" ></div> */}
            {children}
          </li>
        </ul>
        <div className='w-full lg:w-2/6 h-full flex flex-col justify-center items-center bg-electric-blue py-4'>
          <h3 className="text-center text-[14px] font-pepsiowners">{t('connect_with_us_on_social')}</h3>
          <div className="flex lg:w-4/6 items-center justify-center gap-2 w-60 mx-auto my-4 mb-10 lg:mb-0">
            {socialIcons}
          </div>
          <p className='inline lg:hidden text-xs'>{t('copyright')} {date.getFullYear()}</p>
        </div>
      </div>
      {/* <div id="teconsent" ></div> */}
    </footer>
  )
}

export default Footer