import React, { useState } from 'react'
import arrow from './../../assets/images/icons/Arrow-outline-black-sm.svg'
import arrowFill from './../../assets/images/icons/Arrow-filled-sm.svg'

const Accordion = ({title, children, isDefaultOpen = false}) => {
    const [isOpen, setIsOpen] = useState(isDefaultOpen)
    const toggleAccordion = () => setIsOpen(!isOpen)

    return (
        <div className="rounded-lg bg-electric-blue overflow-hidden mb-4" aria-label={title}>
            <button className="group flex w-full hover:bg-digital-alt-blue px-11 py-6 pointer" onClick={()=> toggleAccordion()}>
                <h3 className="text-left text-2xl font-pepsiowners text-white uppercase">{title}</h3>
                <img className={`ml-auto group-hover:${isOpen ? "-rotate-90" : "rotate-90"} transition-all ease-in-out`} src={isOpen ? arrowFill : arrow} alt="" />
            </button>
            <div className={`${(isOpen)?"accordion-open" : "accordion-closed"} overflow-hidden transition-accordion duration-500 ease-in-out`}>
                {children}
            </div>
        </div>
    )
}

export default Accordion