import React from 'react'
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import { Link } from "react-router-dom";
import burstgif from "./../../assets/animations/burst.gif"

const ProductsDropdown = ({ isProductsOpen, setIsProductsClose }) => {
    const { t } = useTranslation('translation');
    const products = t("products", { returnObjects: true })

    const productsTiles = products.map(item => (
        <Link key={"tiles-" + item.slug} to={`/product/${item.slug}`} onClick={() => setIsProductsClose()} className='border-b-2 lg:last:border-b-0 border-r-2 border-vivid-blue'>
            <div className="group relative flex flex-col min-size-44 items-center  overflow-hidden">
                <img className='absolute opacity-0 group-hover:opacity-100 transition-opacity ease-out duration-1000 size-[120%] object-cover' src={burstgif} alt="" />
                <h2 className="mt-6 mb-2 font-pepsiowners text-[14px] text-center px-2 md:text-xl z-10">{item.name}</h2>
                <div className="relative size-60 lg:size-80">
                    <picture className="absolute inset-x-0 block w-6/12 -bottom-5 mx-auto">
                        <source srcSet={require(`./../../assets/images/products/${item.slug}@3x.png`)} media="(min-width: 1024px)" />
                        <img src={require(`./../../assets/images/products/${item.slug}@2x.png`)} alt="Pepsi" />
                    </picture>
                </div>

            </div>
        </Link>
    ))

    return (
        <div className={`${isProductsOpen ? "top-0" : "top-full"} transition-all duration-700 ease-in-out flex flex-col fixed overflow-auto size-full bg-black z-40`}>
            <div className="container pt-[100px] lg:pt-[90px] mx-auto">
                <div className="grid grid-cols-2 lg:grid-cols-3 border-t-2 border-l-2 border-vivid-blue">
                    {productsTiles}
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default ProductsDropdown