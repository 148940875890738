import React from 'react'
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation('translation');
  const switchLang = () => {
    i18n.changeLanguage(t('i18n_button'))
  }

  return (
  <button className="font-pepsiowners" onClick={()=> switchLang()}>{t("i18n_button")}</button>
  )
}

export default LanguageSwitcher