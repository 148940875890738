import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import logo from './../../assets/global/PepsiLogo.svg'
import searchIcon from './../../assets/images/icons/Search.svg'
import chevron from './../../assets/global/arrow.svg'
import hambuger from './../../assets/images/icons/BTN_Menu.svg'
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import MobileMenu from '../MobileMenu/MobileMenu';
import { Link } from "react-router-dom";

const Header = ({isProductsOpen, setIsProductsOpen, toggleProductsSlider, setIsSearchOpen, closeProductsSlider, onChange, searchVal}) => {
  const { t } = useTranslation('translation', {keyPrefix:'header'});
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const hangleMenuChange = (isOpen) => setIsMenuOpen(isOpen)

  const [productsLink, getSocial, ...rest] = t("nav_links", {returnObjects: true })
  const otherLinks = rest.map((link) => (
    <li key={"header-link-" + link.name} className='legal hidden md:inline-block'>
      {(link.isLink) ?
      <Link to={link.url} onClick={()=> closeProductsSlider()} >{link.name}</Link>
      :
      <button onClick={()=> closeProductsSlider()}>{link.name}</button>
      }
    </li>
  ))
  
  return (
    <header className={`${isProductsOpen ? "bg-black": "bg-electric-blue"} px-[19px] transition-colors duration-700 ease-in-out`}>
      <div className='container h-full mx-auto flex items-center'>
        <div className='size-[40px] lg:size-[62px] mr-7'>
          <Link to='/' aria-label={t("home")} onClick={()=> closeProductsSlider()}>
            <img className='size-full object-contain' src={logo} alt="" />
          </Link>
        </div>
        <ul className='hidden sm:flex w-full justify-center md:justify-start gap-7'>
          <li className='legal'>
            <button className='flex items-center' onClick={()=>toggleProductsSlider()}>{productsLink.name}
              <img className='ml-1' src={chevron} alt=''/>
            </button>
          </li>
          <li>
            <a className="legal hidden md:inline-block" href={getSocial.url} onClick={()=> closeProductsSlider()}>
            {getSocial.name}
            </a>
          </li>
          {otherLinks}
        </ul>
        {/* Search & Language */}
        <div className='relative hidden lg:flex h-[44px]'>
          <input className='block bg-transparent border-white border-solid border-2 rounded-full px-7 py-2 pl-12' onChange={onChange} value={searchVal}  />
          <img className="absolute inset-0 m-auto -left-[16rem]" src={searchIcon} alt="" />
          <hr className='h-full border-l-2 mx-3' />
          <LanguageSwitcher />
        </div>
        {/* Hamburger Button */}
        <div className="flex lg:hidden ml-auto">
          <button className='flex items-center' onClick={() => hangleMenuChange(true)} aria-label={t('menu')}>
            <img className='ml-1' src={hambuger} alt=''/>
          </button>
        </div>
      </div>
      <MobileMenu isMenuOpen={isMenuOpen} setIsSearchOpen={(isOpen)=>setIsSearchOpen(isOpen)} setIsProductsOpen={(isOpen)=> setIsProductsOpen(isOpen)} setIsMenuOpen={(isOpen)=> hangleMenuChange(isOpen) } />
    </header>
  )
}

export default Header