import React, {useState} from 'react'
import { Routes, Route } from "react-router-dom";
import './App.css';
import Header from './containers/Header/Header';
import Footer from './containers/Footer/Footer';
import Home from './pages/Home';
import Search from './containers/Search/Search';
import ProductsDropdown from './containers/ProductsDropdown/ProductsDropdown';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import ProductDetail from './pages/ProductDetail';



function App() {
  const [isProductsOpen, setIsProductsOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchVal, setSearchVal] = useState()
  
  const hangleChange = (e) => {
    e.preventDefault();
    const SearchVal = e.target.value
    if(SearchVal.length >= 2) {
      setIsProductsOpen(false)
      setIsSearchOpen(true)
    }
    setSearchVal(SearchVal)
  }

  const handleSearchSlider = (isOpen) => setIsSearchOpen(isOpen)
  const handleProductSlideIn = (isOpen) => setIsProductsOpen(isOpen)

  return (
    <div className={`flex flex-col justify-stretch  ${isSearchOpen ? "overflow-hidden max-h-screen" : "overflow-visible min-h-screen"}`}>
      <Header searchVal={searchVal} isProductsOpen={isProductsOpen} setIsProductsOpen={(isOpen)=> handleProductSlideIn(isOpen)} toggleProductsSlider={()=> handleProductSlideIn(!isProductsOpen)} closeProductsSlider={()=>handleProductSlideIn(false)} setIsSearchOpen={(isOpen)=> handleSearchSlider(isOpen)} onChange={(e)=> hangleChange(e)} />
      <Search searchVal={searchVal} isSearchOpen={isSearchOpen} onChange={(e)=> hangleChange(e)} setIsSearchOpen={()=> setIsSearchOpen()}/>
      <ProductsDropdown isProductsOpen={isProductsOpen} setIsProductsClose={()=>handleProductSlideIn(false)} />
        <Routes future={{ v7_startTransition: true }}>
          <Route exact path='/' element={<Home isSearchOpen={isSearchOpen}/>} />
          <Route path='/product/:slug' element={<ProductDetail isSearchOpen={isSearchOpen} setIsProductsOpen={(isOpen)=> handleProductSlideIn(isOpen)} />} />
          <Route path='/contact' element={<Contact isSearchOpen={isSearchOpen}/>} />
          <Route path='*' element={<NotFound isSearchOpen={isSearchOpen}/>} />
        </Routes>
      <Footer isSearchOpen={isSearchOpen}>
        <div id="teconsent" ></div>
      </Footer>

    </div>
  );
}

export default App;
