import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import banner from "./../assets/images/backgrounds/Module_Products@2x.png"

const Contact = ({isSearchOpen}) => {
    const { t } = useTranslation("translation",{keyPrefix:'contact'});

    useEffect(()=> {
      window.scrollTo(0,0)
    })

  return (
    <main className={isSearchOpen ? "main-search-open":"main-search-close"}>
      <div>
        <img
          className="object-cover w-full mb-6"
          src={banner}
          alt=""
        />
      </div>
      <div className="container max-w-3xl mx-auto pt-3 px-3">
        <h1 className="header-2 text-left my-3">{t("title")}</h1>
        <h2 className="header-3 text-left">
          {t("subtitle")}
        </h2>
        <div className="flex flex-col md:items-stretch md:flex-row md:w-full p-7 my-14 mx-auto bg-electric-blue rounded-lg">
          <div className="w-full flex flex-col mb-5 md:mb-0">
            <h3 className="header-3 text-left mb-6">{t('call_us')}</h3>
            <p>{t('phone_number')} {t('call-days')}</p>
            <p className="mb-6">{t('call-hours')}</p>
            <a
              className="block pill-btn mt-auto hover:text-electric-blue hover:bg-white"
              href="tel:1-800-433-2652"
            >
              {t('phone_number')}
            </a>
          </div>
          <div className="h-px w-full md:w-px md:h-52 my-4 md:my-0 md:mx-7 border-t-2 md:border-l-2 border-white"></div>
          <div className="w-full flex flex-col">
            <h3 className="header-3 text-left">{t('email_us')}</h3>
            <p className="mb-6">{t('email_us_for_general_inquires')}</p>
            <a
              className="block pill-btn mt-auto hover:text-electric-blue hover:bg-white"
              href="https://contact.pepsico.com/pepsica"
            >
              {t('email_now')}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contact;
