import React from "react";
import { useTranslation } from "react-i18next";
import logo from "./../../assets/images/icons/PepsiLogo.svg";
import close from "./../../assets/images/icons/BTN_Close.svg";
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import { Link } from "react-router-dom";

const MobileMenu = ({ isMenuOpen, setIsProductsOpen, setIsMenuOpen, setIsSearchOpen }) => {
    const { t } = useTranslation("translation", { keyPrefix: "header" });

    const closeMenu = () => setIsMenuOpen(false);
    const openProducts = () => {
        closeMenu();
        setIsSearchOpen(false);
        setIsProductsOpen(true);
    }

    const openSearch = () => {
        closeMenu();
        setIsProductsOpen(false);
        setIsSearchOpen(true);
    }

    const openNewPage = () => {
        closeMenu()
        setIsProductsOpen(false);
        setIsSearchOpen(false);
    }

    
    const [productLink, ...rest] = t("nav_links", { returnObjects: true })

    const otherLinks = rest.map((link) => (
        <li key={"mobile-link-" + link.name} className="mb-14">
            {link.isLink ? (
                <Link
                    className="header-3 legal"
                    to={link.url}
                    onClick={() => openNewPage()}
                >
                    {link.name}
                </Link>
            ) : (
                <button className="header-3 legal" onClick={() => closeMenu()}>
                    {link.name}
                </button>
            )}
        </li>
    ));

    return (
        <nav
            className={`fixed inset-0 ${isMenuOpen ? "menu-open" : "menu-close"} transition-all duration-500 ease-in-out overflow-hidden lg:hidden bg-electric-blue z-50`}
        >
            <div className="bg-electric-blue border-b-2 px-4 py-9">
                <div className="container mx-auto flex justify-between items-center">
                    <Link aria-label="home" to="/">
                        <img className="size-10 object-contain" src={logo} alt="" />
                    </Link>
                    <button
                        className="size-5 lg:hidden"
                        onClick={() => closeMenu()}
                        aria-label="menu"
                    >
                        <img className="object-contain size-full" src={close} alt="" />
                    </button>
                </div>
            </div>
            <div className="absolute w-full px-4 py-5">
                <div className="container mx-auto flex justify-end text-2xl">
                    <LanguageSwitcher />
                </div>
            </div>
            <div className="h-full flex items-center justify-center">
                <ul className="h-full flex flex-col justify-center items-center text-center">
                    <li className="mb-14">
                        <button className="header-3 legal" onClick={() => openProducts()}>
                            {productLink.name}
                        </button>
                    </li>
                    {otherLinks}
                    <li className='mb-16'><button className="header-3 legal" onClick={()=> openSearch()}>{t('search')}</button></li>
                </ul>
            </div>
        </nav>
    );
};

export default MobileMenu;
