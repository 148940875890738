import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import logo from './../assets/images/icons/PepsiLogo.svg'
import { Link } from "react-router-dom";

const NotFound = ({isSearchOpen}) => {
    const { t } = useTranslation("translation",{keyPrefix:'not-found'});

    useEffect(()=> {
      window.scrollTo(0,0)
    })

  return (
    <main class={`h-full pb-20 px-3 bg-gradient-to-b from-black to-royal ${isSearchOpen ? "main-search-open":"main-search-close"}`}>
        <div class="container mx-auto flex flex-col items-center mt-5">
            <div class="size-60"><img class="size-full object-cover" src={logo} alt="" /></div>
            <h1 class="header-2 !text-7xl md:!text-9xl">404</h1>
            <h2 class="header-3 mb-3">{t('title')}</h2>
            <p class="mb-9">{t('subtitle')}</p>
            <Link class="pill-btn" to="/">{t('cta.name')}</Link>
        </div>
    </main>
  )
}

export default NotFound